import React, { useState } from "react";
import styles from "./switchButton.module.scss";
import { getRandomString, resolveClassNames } from "palstek";
import { Icon } from "@circle/kip-components";
import PropTypes from "prop-types";

const SwitchButton = ({ ...props }) => {
    const defaultOptions = [];
    const isEnabled = props.isEnabled === undefined ? true : props.isEnabled; // eslint-disable-line no-undefined

    const options = props.options || defaultOptions;

    const [uniqueGroupName] = useState(getRandomString(5));

    const handleOnChange = e => {
        if(e && e.target && e.target.checked && props.onChange)
            props.onChange(e);
    };

    return (
        <span
            className={resolveClassNames(
                styles.container,
                props.className
            )}
            role="radiogroup"
            aria-disabled={!isEnabled}
        >
            {options?.map(opt => {
                const randId = `radio-${uniqueGroupName}-${getRandomString(4)}`;

                return (
                    <React.Fragment key={`${opt.value}-${uniqueGroupName}`}>
                        <input
                            type="radio"
                            disabled={!isEnabled}
                            id={randId}
                            name={uniqueGroupName}
                            value={opt.value}
                            className={styles.radioInput}
                            checked={opt.checked || false}
                            onChange={handleOnChange}
                        />
                        <label htmlFor={randId} className={styles.switchOption}>
                            <span className={styles.switchLabel}>
                                {opt.label || opt.value}
                            </span>
                            <span
                                aria-hidden={true}
                                className={styles.switchLabelPlaceholder}
                            >
                                {opt.label || opt.value}
                            </span>
                        </label>
                    </React.Fragment>
                );
            })}
            {!(props.options?.length > 0) && <Icon _icon="Close" />}
        </span>
    );
};

SwitchButton.propTypes = {
    onChange:  PropTypes.func,
    options:   PropTypes.array,
    className: PropTypes.string,
    isEnabled: PropTypes.bool
};

export { SwitchButton };

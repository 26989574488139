import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Icon } from "@circle/kip-components";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { resolveClassNames } from "palstek";

import { BusyIndicator } from "../../BusyIndicator";
import { numberFormatter } from "../../../helper/numberFormatter";
import { usePrevious } from "../../../hooks/previous";
import { InfiniteList } from "../../generic/InfiniteList";

import styles from "./tableWidget.module.scss";

const TableWidget = ({ ...props }) => {
    const { trigger } = useTrigger();
    const { translate } = useTranslation();
    const params      = useParams();
    const { sorting, options, metadata } = useBranch({
        sorting:  ["sortings", "products"],
        options:  ["queryOptions"],
        metadata: ["metadata", "products"]
    });
    const optionsPrev = usePrevious(options);

    const onFetch = () => {
        trigger("fetchProductTypes", { plantId: params.plantId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: false });
    };

    const onSort = val => {
        const inter = sorting.isOrderAsc ? "desc" : "asc";
        const order = val !== sorting.property ? "desc" : inter;

        trigger("fetchProductTypes", { plantId: params.plantId, orderKey: val, order, reset: true });
        trigger("setSorting", "products", val);
    };

    useEffect(() => {
        trigger("fetchProductTypes", { plantId: params.plantId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: true });
    }, []);

    useEffect(() => {
        if(!options || !optionsPrev) return;

        trigger("fetchProductTypes", { plantId: params.plantId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: true });
    }, [options]);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.headerContainer}>
                <span className={styles.headerTitle}><b>{props.mainHeaderTitle}</b>{" "}{props.secondaryHeaderTitle}</span>
            </div>

            {
                props.isLoading &&
                <BusyIndicator />
            }
            {
                !props.isLoading &&
                <div className={styles.widget}>
                    <div className={resolveClassNames(styles.flexRow, styles.listHeader)}>
                        <div className={resolveClassNames(styles.flex1, styles.clickable)} onClick={() => onSort("name")}>
                            <span>Name</span>
                            {
                                sorting.property === "name" &&
                                <Icon _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                            }
                        </div>
                        <div className={resolveClassNames(styles.flex1, styles.clickable)} onClick={() => onSort("produced")}>
                            <span>{ translate("dashboard.widgets.produced.header").toUpperCase() }</span>
                            {
                                sorting.property === "produced" &&
                                <Icon _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                            }
                        </div>
                        <div className={resolveClassNames(styles.flex1, styles.clickable)} onClick={() => onSort("portion")}>
                            <span>{ translate("overview.proportion").toUpperCase() }</span>
                            {
                                sorting.property === "portion" &&
                                <Icon _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                            }
                        </div>
                    </div>
                    <InfiniteList
                        className={styles.list}
                        metadata={metadata}
                        onFetch={onFetch}
                        element="div"
                        placeholder={<div />}
                        loader={() => <div><span>Loading...</span></div>}
                    >
                        {
                            props.data.map((x, key) => (
                                <div key={key} className={styles.flexRow}>
                                    <div className={styles.flex1}><span className="clamped">{x.name}</span></div>
                                    <div className={styles.flex1}><span className="clamped">{numberFormatter(x.produced, 999999)}</span></div>
                                    <div className={styles.flex1}><span>{x.portion}%</span></div>
                                </div>
                            ))
                        }
                    </InfiniteList>
                </div>
            }
        </div>
    );
};

TableWidget.propTypes = {
    mainHeaderTitle:      PropTypes.string,
    secondaryHeaderTitle: PropTypes.string,
    data:                 PropTypes.arrayOf(PropTypes.object),
    isLoading:            PropTypes.bool
};

export { TableWidget };

import { useTranslation } from "@circle/gestalt-app";
import PropTypes from "prop-types";

import styles from "./overviewFilter.module.scss";
import { SwitchButton }  from "../../generic/switchButton/SwitchButton";

const OverviewFilter = ({ ...props }) => {
    const { translate }         = useTranslation();

    const onSelect = e => {
        const value = {
            orderKey: e.target.value.replace("Desc", "").replace("Asc", ""),
            order:    e.target.value.toLowerCase().includes("asc") ? "asc" : "desc"
        };

        props.onChange(value);
    };

    const options = [
        {
            value:   "nameAsc",
            checked: props.orderKey === "name" && props.order === "asc",
            label:   translate("filter.name.ascending")
        }, {
            value:   "nameDesc",
            checked: props.orderKey === "name" && props.order === "desc",
            label:   translate("filter.name.descending")
        }, {
            value:   "techAvailabilityDesc",
            checked: props.orderKey === "techAvailability" && props.order === "desc",
            label:   translate("filter.techAvailability.descending")
        }, {
            value:   "techAvailabilityAsc",
            checked: props.orderKey === "techAvailability" && props.order === "asc",
            label:   translate("filter.techAvailability.ascending")
        }, {
            value:   "performanceDesc",
            checked: props.orderKey === "performance" && props.order === "desc",
            label:   translate("filter.performance.descending")
        }, {
            value:   "performanceAsc",
            checked: props.orderKey === "performance" && props.order === "asc",
            label:   translate("filter.performance.ascending")
        }
    ];

    return (
        <SwitchButton
            className={styles.filter}
            onChange={onSelect}
            options={options}
        />
    );
};

OverviewFilter.propTypes = {
    onChange: PropTypes.func,
    orderKey: PropTypes.string,
    order:    PropTypes.string
};

export { OverviewFilter };

import React from "react";
import styles from "./button.module.scss";
import { resolveClassNames, autoFilterProps } from "palstek";
import PropTypes from "prop-types";

const Button = ({ ...props }) => {
	const _appearance = !props._appearance ? "default" : props._appearance;
	const _variant = !props._variant ? "default" : props._variant;

    const variants = {
        default: styles.default,
        icon:    styles.icon,
        small:   styles.small
    };

    const appearances = {
        default:     styles.default,
        primary:     styles.primary,
        secondary:   styles.secondary,
        transparent: styles.transparent
    };

	return (
    <button
			className={resolveClassNames(
				styles.container,
				props.className,
				_appearance && appearances[_appearance],
				_variant && variants[_variant],
				props._halfDisabled && styles.halfDisabled
			)}
			{...autoFilterProps(props, "className", "type")}
			type={props.type || "button"}
    >
        {props.children}
    </button>
	);
};

Button.propTypes = {
    onChange:      PropTypes.func,
    children:      PropTypes.node,
    className:     PropTypes.string,
    _appearance:   PropTypes.string,
    _variant:      PropTypes.string,
    type:          PropTypes.string,
    _halfDisabled: PropTypes.bool
};

export { Button };
